/* StarRating.css */
.star-rating {
    display: inline-flex; /* Use inline-flex to make the stars display in a row */
    align-items: center; /* Align items vertically in the center */
  }
  
  .star {
    width: 20px;
    height: 20px;
    background-color: #ccc;
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    margin-right: 5px;
  }
  
  .filled {
    background-color: #ffd700; /* Color for filled stars */
  }
  
  .half-filled {
    background: conic-gradient(
      from 90deg,
      #ffd700 0%,
      #ffd700 50%,
      #ccc 50%,
      #ccc 100%
    );
  }
