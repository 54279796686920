/* Dans votre fichier CSS */
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-0.25deg); }
    20% { transform: translate(-3px, 0px) rotate(0.25deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(0.25deg); }
    50% { transform: translate(-1px, 2px) rotate(-0.25deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-0.25deg); }
    80% { transform: translate(-1px, -1px) rotate(0.25deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-0.25deg); }
  }
  
  .shake-animation {
    animation: shake 3s;
    animation-iteration-count: infinite;
  }