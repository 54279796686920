/* Ajoutez ces styles dans votre fichier CSS */
@keyframes scrollHint {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0);
  }
}
.scrollable-container {
    height: 50vh;
    overflow-y: auto;
    width: 100%;
  }
  
  .glass-card {
    position: relative;
    width: 90%;
    height: 100%;
    margin: 0 auto; /* Ajoutez cette ligne pour centrer horizontalement */
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .card-content {
    padding: 16px;
    animation: scrollHint 1s ease-in-out;

  }
  
  .allergens-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .allergen {
    border: 1px solid rgba(192, 192, 192, 0.5);
    padding: 5px;
    margin: 5px;
  }
  
  .quantity-container {
    display: flex;
    align-items: center;
  }
  
  .review {
    /* Styles pour les avis */
  }
  
  @media only screen and (max-width: 480px) {
    .glass-card {
      width: 135%;
    }
  }
  /* Add these styles to your CSS file */

/* Hide the default checkbox */
.custom-checkbox-input {
  display: none;
}

.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

/* Create a custom checkbox */
.custom-checkbox-input ~ .checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: #2196F300;
  border-radius: 0.25em;
  transition: all 0.25s;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox-input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-checkbox-input ~ .checkmark:after {
  content: "";
  position: absolute;
  transform: rotate(0deg);
  border: 0.1em solid black;
  left: 0;
  top: 0;
  width: 1.05em;
  height: 1.05em;
  border-radius: 0.25em;
  transition: all 0.25s, border-width 0.1s;
}

/* Show the checkmark when checked */
.custom-checkbox-input:checked ~ .checkmark:after {
  left: 0.45em;
  top: 0.25em;
  width: 0.25em;
  height: 0.5em;
  border-color: #fff0 white white #fff0;
  border-width: 0 0.15em 0.15em 0;
  border-radius: 0em;
  transform: rotate(45deg);
}
/* YourRadioStyles.css */

.form-input-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  height: var(--radio-size);
  width: var(--radio-size);
  outline: none;
  margin: 0;
  cursor: pointer;
  border: 2px solid var(--radio);
  background: transparent;
  border-radius: 50%;
  display: grid;
  justify-self: end;
  justify-items: center;
  align-items: center;
  overflow: hidden;
  transition: border .5s ease;
}

/* Add the rest of your styles */
