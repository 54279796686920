.HomePage > *:not(.SplashScreenWrapper) {
  background-color: #161b22;
  width: 90%;
  margin: 0 auto; /* Appliquer une marge automatique sur les côtés gauche et droit */
}
.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border-color:rgba(192, 192, 192, 0.5);
  background-color: rgba(192, 192, 192, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease; /* Animation de transition */
}

.point {
  width: 3px;
  height: 3px;
  background-color: rgba(192, 192, 192, 5);
  border-radius: 50%;
  margin: 0 2px;
}

.expanded {
  width: 35px;
  height: 35px;
}



