
.splash-screen {
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 1s ease-in-out;
  z-index: 1000;
  backdrop-filter: blur(10px); /* Applique un flou de 10px à l'arrière-plan */
}

  .splash-content {
    text-align: center;
    color: white;
  }
  
  /* Hide the splash screen when animation is complete */
  .splash-screen.hide {
    opacity: 0;
    pointer-events: none;
  }
  .main {
    position: relative;
    max-height: 200px;
  }
  .blurry-background-text {
    position: relative;
    z-index: 2;
  }
  
  .blurry-background-text::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -10px; /* Ajustez ces valeurs selon le besoin */
    left: -10px;
    right: -10px;
    bottom: -10px;
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.7); /* Assurez-vous que cette couleur s'aligne avec votre design */
  }
  
  .input {
    max-width: 190px;
    border: none;
    border-bottom: solid gray 1px;
    background: transparent;
    outline: none;
    padding: 10px;
    transition: 0.2s;
    color: #e8e8e8;
    font-size: 17px;
  }
  
  .input:focus , .input:valid {
    border-color: lightblue;
  }
  
  label span {
    position: absolute;
    top: 0px;
    display: block;
    transition: 0.2s;
    pointer-events: none;
    font-size: 25px;
    color: whitesmoke;
    opacity: 0%;
  }
  
  .input:focus + label span ,.input:valid + label span {
    color: lightblue;
    top: -25px;
    opacity: 100%;
  }
  
  .input:focus + label p ,.input:valid + label p {
    opacity: 0;
  }
  .cssbuttons-io-button {
    background: #a370f0;
    color: white;
    font-family: inherit;
    padding: 0.35em;
    padding-left: 1.2em;
    font-size: 17px;
    font-weight: 500;
    border-radius: 0.9em;
    border: none;
    letter-spacing: 0.05em;
    display: flex;
    align-items: center;
    box-shadow: inset 0 0 1.6em -0.6em #714da6;
    overflow: hidden;
    position: relative;
    height: 2.8em;
    padding-right: 3.3em;
    cursor: pointer;
  }
  
  .cssbuttons-io-button .icon {
    background: white;
    margin-left: 1em;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.2em;
    width: 2.2em;
    border-radius: 0.7em;
    box-shadow: 0.1em 0.1em 0.6em 0.2em #7b52b9;
    right: 0.3em;
    transition: all 0.3s;
  }
  
  .cssbuttons-io-button:hover .icon {
    width: calc(100% - 0.6em);
  }
  
  .cssbuttons-io-button .icon svg {
    width: 1.1em;
    transition: transform 0.3s;
    color: #7b52b9;
  }
  
  .cssbuttons-io-button:hover .icon svg {
    transform: translateX(0.1em);
  }
  
  .cssbuttons-io-button:active .icon {
    transform: scale(0.95);
  }
  .centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* 100% of the viewport height */
  }
  .logo-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .logo {
    width: 50px; /* Taille initiale du logo */
    height: 50px;
    border-radius: 50%; /* Rend le logo rond */
    transition: all 1s ease-in-out; /* Ajoute une transition pour une animation fluide */
  
    /* Définir d'autres styles pour le logo si nécessaire */
  }
  
  .logo.active {
    width: 90vw; /* Taille du logo lorsqu'il est agrandi presque pour recouvrir l'écran */
    height: 90vw;
    box-shadow: 0 0 50px 50px rgba(255, 255, 255, 0.7); /* Ajoute une ombre avec une couleur et une intensité changeante */
  }
    